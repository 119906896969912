import { useState, useEffect } from 'react';
import Header from './include/header';
import Footer from './include/footer';
import LeftPanel from './include/LeftPanel';
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import AuthUser from "../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Global from "../APIConfig";


function RateCalulator() {
    let navigate = useNavigate();
    let { type } = useParams();
    const [showpage, setShowpage] = useState(type);
    const [paymenttype, setPaymenttype] = useState('Prepaid');
    const [codprice, setCodprice] = useState('');
    const [insurance, setInsurance] = useState('No');
    const [senderPostalcode, setSenderPostalcode] = useState('');
    const [receiverPostalcode, setReceiverPostalcode] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [length, setLength] = useState('');
    const [width, setWidth] = useState('');
    const [countrylist, setCountrylist] = useState([]);
    const [sendercountryname, setSendercountryname] = useState('');
    const [sendercountryid, setSendercountryid] = useState('');
    const [receivercountryname, setReceivercountryname] = useState('');
    const [receivercountryid, setReceivercountryid] = useState('');
    const [defaultCurrency, setDefaultCurrency] = useState('₹');
    const [defaultCountry, setDefaultCountry] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [servicelist, setServicelist] = useState([]);
    const { headers1, headers2, logout } = AuthUser();
    const [product_Type, setProduct_Type] = useState('Parcel');
    const [userdata, setUserdata] = useState('');
    const [receivercityName, setReceivercityName] = useState('');
    const [receivercityId, setReceivercityId] = useState('');
    const [sendercityName, setSendercityName] = useState('');
    const [sendercityId, setSendercityId] = useState('');

    useEffect(() => {
        setShowpage(type);
        GetdefaultCountryid();
        GetdefaultCountry();
        GetCountrylist();
        const items = JSON.parse(localStorage.getItem('user'));
        if (items) {
            setUserdata(items);
        }
    }, []);

    const GetCountrylist = () => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'Countrylist',
            data: {
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setCountrylist(data);
                }
            })
            .catch(function (error) {
                // console.log(error);
                // setLoading(false);
            });
    }

    const GetdefaultCountryid = () => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'SiteconfigData',
            data: {
                fliedname: "default_country"
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setDefaultCountry(data);
                    setSendercountryid(data);
                }
            })
            .catch(function (error) {
                // console.log(error);
                // setLoading(false);
            });
        axios({
            method: 'post',
            url: Global.baseUrl + 'SiteconfigData',
            data: {
                fliedname: "default_currency"
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setDefaultCurrency(data);
                }
            })
            .catch(function (error) {
                // console.log(error);
                // setLoading(false);
            });
    }

    const GetdefaultCountry=()=>
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'defaultcountryname',
                data: {
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    var data=response.data.value;
                    setSendercountryname(data);
                    setReceivercountryname(data);
                }
              })
              .catch(function (error) {
                // console.log(error);
                // setLoading(false);
              });
        }


    const handlereset = () => {
        window.location.reload();
    }

    const handleservice = () => {

        if (sendercountryname == "" && showpage == 'International') {
            toast.warn('Please Select Pickup Country');
        }
        else if (receivercountryname == "" && showpage == 'International') {
            toast.warn('Please Select Delivery Country');
        }
        else if (senderPostalcode == "" && showpage == 'Domestic') {
            toast.warn('Please Enter Pickup Pincode');
        }
        else if (receiverPostalcode == "" && showpage == 'Domestic') {
            toast.warn('Please Enter Delivery Pincode');
        }
        else if (weight == "") {
            toast.warn('Please Enter Weight');
        }
        else if (height == "") {
            toast.warn('Please Enter Height');
        }
        else if (width == "") {
            toast.warn('Please Enter Width');
        }
        else if (length == "") {
            toast.warn('Please Enter Length');
        }
        else if (productPrice == "") {
            toast.warn('Please Enter Shipment Value');
        }
        else if (paymenttype =='COD' && codprice == "") {
            toast.warn('Please Enter COD Value');
        }
        else {
            axios({
                method: 'post',
                url: Global.baseUrl + 'ratecalulator',
                headers: headers1,
                data: {
                    sendercountryid: sendercountryid,
                    sendercityId : sendercityId,
                    senderpincode : senderPostalcode,
                    receivercountryid: receivercountryid,
                    receivercityId: receivercityId,
                    receiverpincode: receiverPostalcode,
                    productPrice: productPrice,
                    height: height,
                    width: width,
                    length: length,
                    weight: weight,
                    booking_Type: showpage,
                    product_Type: product_Type,
                    insurance: insurance,
                    paymenttype: paymenttype,
                    codprice: codprice,
                    type: userdata.usertype,
                    agent_id: 0,

                }
            })
                .then(function (response) {
                    if (response.data.status == 200) {
                        setServicelist(response.data.value);
                    }
                    else {
                        toast.warn(response.data.message);
                        setServicelist([]);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        toast.error(error.response.statusText);
                        logout();
                        navigate('/');
                        window.location.reload();
                    }
                    // console.log(error);
                });
        }
    }

    const handelsendercountry=(e)=>{
        setSendercountryname(e.target.options[e.target.selectedIndex].text);
        setSendercountryid(e.target.value);
      }

      const handelreceivercountry=(e)=>{
        setReceivercountryname(e.target.options[e.target.selectedIndex].text);
        setReceivercountryid(e.target.value);
      }

    const handelsenderpostalcode=(zipcode)=>{
        setServicelist([]);
        axios({
            method: 'post',
            url: Global.baseUrl+'countrydatabypincode',
            headers:headers1,
            data: {
                Postalcode: zipcode,
            }
        })
        .then(function (response) {
            if(response.data.status == 200)
            {
                setSendercountryid(response.data.value.countryid);
                setSendercityName(response.data.value.city);
                setSendercityId(response.data.value.id);
            }
            else
            {
                setSendercityName('');
                setSendercityId('');
            }
        })
        .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
        });
        }

    const handelreceiverpostalcode=(zipcode)=>{
        setServicelist([]);
    axios({
    method: 'post',
    url: Global.baseUrl+'countrydatabypincode',
    headers:headers1,
    data: {
        Postalcode: zipcode,
    }
    })
    .then(function (response) {
    if(response.data.status == 200)
    {
        setReceivercountryid(response.data.value.countryid);
        setReceivercityName(response.data.value.city);
        setReceivercityId(response.data.value.id);
        
    }
    else
    {
        setReceivercountryid('');
        setReceivercityName('');
        setReceivercityId('');
    }
    })
    .catch(function (error) {
    if(error.response.status==401){
        toast.error(error.response.statusText);
        logout();
        navigate('/');
        window.location.reload();
    }
    // console.log(error);
    });
    }

    return (
        <>
            <LeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer />
                <section className="mb-3 mt-3 tools">
                    <div className="">
                        <div className="row">
                            <div className="col-12 col-md">
                                <ul className="nav nav-pills mb-2">
                                    <li className="nav-item">
                                        <a href="/RateCalulator/Domestic" className={showpage == 'Domestic' ? "btn btn-primary" : "btn btn-outline-primary"}>Domestic</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/RateCalulator/International" className={showpage == 'International' ? "btn btn-primary" : "btn btn-outline-primary"}>International</a>
                                    </li>
                                </ul>
                                <div className="card border-0 order_box shadow-sm">
                                    <div className="card-body">
                                        <section className="pb-5">
                                            <div className="row">
                                                <div className="col-12 col-md-12 mb-3">
                                                    <label className="form-label">Shipment Product Type:</label>
                                                    <div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="Parcel" checked={product_Type == 'Parcel'} onChange={() => setProduct_Type('Parcel')} />
                                                            <label className="form-check-label" for="Parcel">Parcel</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="Document" checked={product_Type == 'Document'} onChange={() => setProduct_Type('Document')} />
                                                            <label className="form-check-label" for="Document">Document</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {showpage === 'International' ? (
                                                    <>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label className="form-label">Country <span className="text-danger">*</span>:</label>
                                                            <select className="form-select" onChange={handelsendercountry}>
                                                                <option>--Please Select--</option>
                                                                {countrylist.map((cl, i) => {
                                                                    return (
                                                                        <option value={cl.id} selected={defaultCountry == cl.id} key={i}>{cl.country}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label className="form-label">Country <span className="text-danger">*</span>:</label>
                                                            <select className="form-select"  onChange={handelreceivercountry}>
                                                                <option>--Please Select--</option>
                                                                {countrylist.map((cl, i) => {
                                                                    return (
                                                                        <option value={cl.id} selected={defaultCountry == cl.id} key={i}>{cl.country}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="col-6 col-md-6 mb-3">
                                                            <label for="" className="form-label">Pickup Pincode:</label>
                                                            <input type="text" className="form-control" id="" placeholder="Enter pickup pincode" onChange={(e)=>{setSenderPostalcode(e.target.value);handelsenderpostalcode(e.target.value)}} />
                                                        </div>
                                                        <div className="col-6 col-md-6 mb-3">
                                                            <label for="" className="form-label">Delivery Pincode:</label>
                                                            <input type="text" className="form-control" id="" placeholder="Enter delivery pincode" onChange={(e) => {setReceiverPostalcode(e.target.value);handelreceiverpostalcode(e.target.value)}} />
                                                        </div>
                                                    </>
                                                )}
                                                <div className="col-12 col-md-6 mb-3">
                                                    <label for="" className="form-label">Actual Weight:</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Enter Weight" onChange={(e) => setWeight(e.target.value)} />
                                                        <span className="input-group-text">KG</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 mb-3">
                                                    <label for="" className="form-label">Dimensions:(H x W x L)</label>
                                                    <div className="row">
                                                        <div className="col-12 col-md-4 mb-3">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="H" onChange={(e) => setHeight(e.target.value)} />
                                                                <span className="input-group-text">CM</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mb-3">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="W" onChange={(e) => setWidth(e.target.value)} />
                                                                <span className="input-group-text">CM</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-4 mb-3">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" placeholder="L" onChange={(e) => setLength(e.target.value)} />
                                                                <span className="input-group-text">CM</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 mb-3">
                                                    <label for="" className="form-label">Payment Type:</label>
                                                    <div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineRadiod20" checked={paymenttype == 'Prepaid'} onChange={() => setPaymenttype('Prepaid')} />
                                                            <label className="form-check-label" for="inlineRadiod20">Prepaid <a href="#" title="Order Payment already received from the buyer"><i className="bx bx-help-circle"></i></a></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineRadiod21" checked={paymenttype == 'COD'} onChange={() => setPaymenttype('COD')} />
                                                            <label className="form-check-label" for="inlineRadiod21">Cash On Delivery <a href="#" title="Order Payment to be collected from the buyer on shipment delivery"><i className="bx bx-help-circle"></i></a></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {paymenttype == 'COD' &&
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label for="" className="form-label">COD Price ({defaultCurrency}):</label>
                                                        <div className="input-group">
                                                            <span className="input-group-text">₹</span>
                                                            <input type="text" className="form-control" placeholder="Enter COD values" onChange={(e) => setCodprice(e.target.value)} />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-12 col-md-6 mb-3">
                                                        <label for="" className="form-label">Shipment Value ({defaultCurrency}):</label>
                                                        <div className="input-group">
                                                            <span className="input-group-text">₹</span>
                                                            <input type="text" className="form-control" placeholder="Enter price values" onChange={(e)=>setProductPrice(e.target.value)} />
                                                        </div>
                                                    </div>
                                                <div className="col-12 col-md-6 mb-3">
                                                    <label for="" className="form-label">Shipping Dangerous Goods?:</label>
                                                    <div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="cr" checked={insurance == 'Yes'} onChange={() => setInsurance('Yes')} />
                                                            <label className="form-check-label" for="cr">Yes <a href="#" title="Carrier Risk"><i className="bx bx-help-circle"></i></a></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="or" value="option2" checked={insurance == 'No'} onChange={() => setInsurance('No')} />
                                                            <label className="form-check-label" for="or">No <a href="#" title="Owner Risk"><i className="bx bx-help-circle"></i></a></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button className="btn btn-primary" onClick={() => { handleservice() }}>Calculate</button>
                                                <button className="btn btn-outline-primary" onClick={() => { handlereset() }}>Reset</button>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <h5 className="bg-primary text-white p-2 text-center">Pricing Plans</h5>
                                <div className="bg-white p-2">
                                    <div className="table-responsive">
                                        {servicelist != undefined && servicelist.length > 0 ?
                                            <>
                                                {servicelist.map((ser, i) => {
                                                    const hasTotalPrice = ser.totalprice && ser.totalprice !== null && ser.totalprice !== '0.00';
                                                    return (
                                                        hasTotalPrice && (
                                                            <div className="list-group mb-3" key={i}>
                                                                <button type="button" className={`list-group-item list-group-item-action`}>
                                                                    <div className="text-center">
                                                                        <h2><small>{defaultCurrency}</small>{ser.totalprice}</h2>
                                                                        <h6>{ser.services_name}</h6>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </>
                                            :
                                            <div className="list-group">
                                                <a href="#" className="list-group-item list-group-item-action bg-danger">
                                                    <div className="text-center text-white">
                                                        <h2 className=""><i className="bx bx-error"></i></h2>
                                                        <h6>No service available for the selected locations</h6>
                                                    </div>
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </main>
        </>
    );
}

export default RateCalulator;
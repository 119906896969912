import React, { useState, useEffect } from "react";
import axios from "axios";
import Global from "../../APIConfig";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Barcode from "../../Barcode";
import notfound from '../../notFound.png';

function ShipmentPrintReceipt() {
    let navigate = useNavigate();
    const { slip_no } =useParams();
    const [shipmentdata, setShipmentdata] = useState([]);
    const { headers1, headers2, logout } = AuthUser();
    useEffect(() => {
        GetInvoice();
    }, []);
    const GetInvoice = () => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'CustomerShipmentInvoice',
            headers: headers1,
            data: {
                slip_no: slip_no
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setShipmentdata(data);
                }
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
	  	            <div className="panel" id="invoice">
		                <div className="panel-body">
		                    <div className="row">
                                <div className="col-sm-8 top-left invoice_logo">
                                    <img src={`${Global.logoUrl}${shipmentdata.Defaultlogo}`} className="" style={{"background-color":"#999", "-webkit-filter": "grayscale(100%)", "filter": "grayscale(100%)"}}/>
                                </div>
                                <div className="col-sm-4 text-rigth pt-3">
                                        <h3 className="marginright">{shipmentdata.systemcompanyname}</h3>
                                </div>
			                </div>
			                <hr/>
                            <div className="row">
                                <div className="col-md-4 from">
                                    <p className="receiptfont">Shipment info </p>
                                    <p className="lead1">Origin : <small style={{"color":"#999"}}>{shipmentdata.sendercity}</small></p>
                                    <p className="lead1">Destination : <small style={{"color":"#999"}}>{shipmentdata.receivercity}</small></p>
                                    <p className="lead1">Tracking Number : <small style={{"color":"#999"}}>{shipmentdata.slip_no}</small></p>
                                </div>
                                <div className="col-md-4 to">
                                    <p className="receiptfont">&nbsp;</p>
                                    <p className="lead1">package_no. : <small style={{"color":"#999"}}>1</small></p>
                                    <p className="lead1">Chargeable_Weight : <small style={{"color":"#999"}}>{shipmentdata.volumetric_weight} {shipmentdata.weightType}</small></p>
                                    <p className="lead1">shipment_type : 
                                        <small style={{"color":"#999"}}>
                                        {shipmentdata.booking_mode=='Prepaid' ?
                                            <span>Prepaid</span>
                                            :
                                            <span>Cash On Delievery</span>
                                        }
                                        </small>
                                    </p>
                                    <p className="lead1">Product Type : <small style={{"color":"#999"}}>{shipmentdata.nrd}</small></p>
                                </div>
                                <div className="col-md-4 to">
               	                    <p className="receiptfont">&nbsp;</p>
                                    <h3 className="marginright_new"><Barcode value={slip_no} /></h3>
               		
                                </div>
               
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-4 from">
                                    <p className="receiptfont">from (shipper) : {shipmentdata.sender_name}</p>
                                    <p className="lead1">{shipmentdata.sender_address}</p>
                                    <p className="lead1">{shipmentdata.sendercity},{shipmentdata.senderstate}</p>
                                    <p className="lead1">{shipmentdata.sendercountry}, {shipmentdata.sender_zip}</p>
                                    <p className="lead1">company_name: {shipmentdata.sender_company_name}</p>
                                    <p className="lead1">phone: {shipmentdata.sender_phone}</p>
                                    <p className="lead1">email: {shipmentdata.sender_email}</p>
                                </div>
                                <div className="col-md-4 to">
                                    <p className="receiptfont">to (reciever) : {shipmentdata.reciever_name}</p>
                                    <p className="lead1">{shipmentdata.reciever_address}</p>
                                    <p className="lead1">{shipmentdata.receivercity}, {shipmentdata.receiverstate}</p>
                                    <p className="lead1">{shipmentdata.receivercountry}, {shipmentdata.reciever_zip}</p>
                                    <p className="lead1">company_name: {shipmentdata.receiver_company_name}</p>
                                    <p className="lead1">phone: {shipmentdata.reciever_phone}</p>
                                    <p className="lead1">email: {shipmentdata.reciever_email}</p>
                                </div>
                                <div className="col-md-4 text-right">
                                    <p className="receiptfont">Payment Details</p>
                                    <p className="lead1">date: {shipmentdata.entrydate}</p>
                                    <p className="lead1">total_amount: {shipmentdata.gDefaultCurrency} {shipmentdata.total_amt}</p>
                                </div>
                            </div>
			                <div className="row table-row">
				                <table className="table table-striped">
                                    <thead className="table-dark">
                                        <tr style={{"background-color":"#000;"}}>
                                        <th className="text-center" style={{"width":"5%"}}>#</th>
                                        <th style={{"width":"50%"}}>Charges Detail</th>
                                        <th className="text-right" style={{"width":"15%"}}>{shipmentdata.total_price}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center tabletd">#</td>
                                            <td className="tabletd">Shipping</td>
                                            <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.service_charge}</td>
                                        </tr>
                                        {shipmentdata.fuel_surcharge!='0' &&
                                        <tr>
                                            <td className="text-center tabletd">#</td>
                                            <td  className="tabletd">Fuel Surcharge</td>
                                            <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.fuel_surcharge}</td>
                                        </tr>
                                        }
                                        {shipmentdata.insurence_charge!='0' &&
                                        <tr>
                                            <td className="text-center tabletd">#</td>
                                            <td className="tabletd">Insurance Charge</td>
                                            <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.insurence_charge}</td>
                                        </tr>
                                        }
                                        {shipmentdata.cod_surcharge!='0' &&
                                        <tr>
                                            <td className="text-center tabletd">#</td>
                                            <td className="tabletd">COD Charge</td>
                                            <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.cod_surcharge}</td>
                                        </tr>
                                        }
                                        {shipmentdata.custom_surcharge!='0' &&
                                        <tr>
                                            <td className="text-center">#</td>
                                            <td className="tabletd">Custom Clearance Charge</td>
                                            <td className="text-right">{shipmentdata.gDefaultCurrency} {shipmentdata.custom_surcharge}</td>
                                        </tr>
                                        }
                                        {shipmentdata.ESS_charges!='0' &&
                                        <tr>
                                            <td className="text-center tabletd">#</td>
                                            <td className="tabletd">Temporary Emergency Situation Surcharge (ESS)</td>
                                            <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.ESS_charges}</td>
                                        </tr>
                                        }
                                        {shipmentdata.elevated_risk_charges!='0' &&
                                        <tr>
                                            <td className="text-center tabletd">#</td>
                                            <td className="tabletd">Elevated Risk Charge</td>
                                            <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.elevated_risk_charges}</td>
                                        </tr>
                                        }
                                        {shipmentdata.resticted_destination_charges!='0' &&
                                        <tr>
                                            <td className="text-center tabletd">#</td>
                                            <td className="tabletd">Resticted Destination Charges</td>
                                            <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.resticted_destination_charges}</td>
                                        </tr>
                                        }
                                        {shipmentdata.remote_area_delivery_charges!='0' &&
                                        <tr>
                                            <td className="text-center tabletd">#</td>
                                            <td className="tabletd">Remote Area Delivery Charges</td>
                                            <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.remote_area_delivery_charges}</td>
                                        </tr>  
                                        }
                                        <tr>
                                        <td className="text-center tabletd">#</td>
                                        <td className="tabletd">{shipmentdata.taxtype} ({shipmentdata.defaultservicetax}%)</td>
                                        <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.service_tax}</td>
                                        </tr>
                                        {shipmentdata.vat!='0' &&
                                        <tr>
                                            <td className="text-center tabletd">#</td>
                                            <td className="tabletd">VAT ({shipmentdata.defaultvat}%)</td>
                                            <td className="text-right tabletd">{shipmentdata.gDefaultCurrency} {shipmentdata.vat}</td>
                                        </tr>
                                        }
                                    </tbody>
			                    </table>
			                </div>
			                <div className="row">
			                    <div className="col-md-4 margintop">
                                    <div className="recipt_heading">
                                        <div className="panel panelwarning">
                                            <div className="panelheading">
                                                <p className="lead2">Receiver's Signature</p>
                                                {shipmentdata.signature_img != undefined && shipmentdata.signature_img != "" ?
                                                    <img src={`${Global.logoUrl}${shipmentdata.signature_img}`} className="" style={{"height":"100px","padding":"10px"}}/>
                                                :
                                                    <img src={notfound} className="" style={{"height":"100px","padding":"10px"}}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
			                    </div>
                                <div className="col-md-4 margintop">
                                    <div className="recipt_heading">
                                        <div className="panel panelwarning">
                                            <div className="panelheading">
                                                <p className="lead2">Receiver's Image</p>
                                                {shipmentdata.receiver_image != undefined && shipmentdata.receiver_image != "" ?
                                                    <img src={`${Global.logoUrl}${shipmentdata.receiver_image}`} className="" style={{"height":"100px","padding":"10px"}}/>
                                                :
                                                    <img src={notfound} className="" style={{"height":"100px","padding":"10px"}}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
			                    </div>
			                    <div className="col-md-4">
                                    <p style={{"paddingLeft":"22%"}}>Total : {shipmentdata.gDefaultCurrency} {shipmentdata.total_amt} </p>
			                    </div>
			                </div>
                            <div className="col-md-12 text-center">
                                <p className="lead1">Registered Office : {shipmentdata.systemcompanyname} : {shipmentdata.Defaultcompanyaddress}.</p>
                            </div>
		                </div>
		            </div>
	            </div>
            </div>
        </div>
      );       
}
export default ShipmentPrintReceipt;